import React from "react";
import {List, ListItem, styled, Typography} from "@mui/material";
import {green} from "@mui/material/colors";

export default function QuizQuestion({risposte, corretta, answer, sendAnswer, poll}) {
    const RispostaStyled = styled(ListItem)({
        border: '2px solid #0C96CC',
        borderRadius: '0.7rem',
        background:'#0C96CC',
        margin: '1rem 0',
        cursor: 'pointer',
    })

    const computeBkg = (r) => {
        if(!poll) {
            if (answer !== '') {
                if (corretta === r) {
                    return green[500]
                } else {
                    if(answer === r  && answer !== corretta){
                        return '#DB0A16'
                    } else {
                        return 'rgba(12,150,204,0.45)'
                    }
                }
            }
        } else {
            if(answer === r){
                return '#8A4C94'
            } else if (answer && answer !== r){
                return 'rgba(12,150,204,0.45)'
            }
        }

    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? '2px solid '+ computeBkg(r) : '2px solid #0C96CC',
                color: 'white',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400'>
                    {i + 1}. {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(poll) {
            sendAnswer(r)
        } else {
            if(answer === ''){

                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}
