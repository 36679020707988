import {createTheme} from "@mui/material";

const clap1 = '#0C96CC'
const clap2 = '#424482'
const siNo1='#3a4de4'
const siNo2='#00d8a1'
const sentiment1='#3a4de4'
const sentiment2='#00d8a1'

const pleshDark = createTheme({

    typography: {
      fontFamily: ['roboto']
    },
    components:{
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: 'linear-gradient(130deg, #0C96CC 0%, #424482 70%, #8A4C94 130%)',
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                },
            },
        },
        MuiDialog:{
            styleOverrides:{
                paper:{
                    background: 'linear-gradient(130deg, #0C96CC 0%, #424482 70%, #8A4C94 130%)',
                }
            }
        },
        MuiTextField:{
            styleOverrides:{
                root:{
                    '& fieldset':{
                        borderWidth:'3px !important',
                        borderRadius:'1rem'
                    }
                }
            }
        },
        MuiButton:{
            variants: [
                //siNo
                {
                    props: {variant:'siNo'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'si'},
                    style:{
                        background: 'linear-gradient('+siNo1+', '+siNo1+')',
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'no'},
                    style:{
                        background: 'linear-gradient('+siNo2+', '+siNo2+')',
                    },
                },
                //sentiment
                {
                    props: {variant:'sentiment'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'daccordo'},
                    style:{
                        background: 'linear-gradient('+sentiment1+', '+sentiment1+')',
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'nonDaccordo'},
                    style:{
                        background: 'linear-gradient('+sentiment2+', '+sentiment2+')',
                    },
                },
                {
                    props: {variant: 'clap'},
                    style:{
                        height: '100px',
                        width:'80%',
                        fontSize: '18px',
                        background: 'linear-gradient(90deg, '+clap1+' 0%, '+clap2+')',
                        border:'2px solid white',
                        color: '#f1ebe9',
                        borderRadius: '2rem',
                    }
                },
                {
                    props: {variant: 'wordcloud'},
                    style:{
                        background: 'linear-gradient(90deg, #0d6efd 0%, #0d6efd)',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#fff',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                },
                {
                    props: {variant: 'qna'},
                    style:{
                        background: 'linear-gradient(90deg, #EA4D95 0%, #E76E51)',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#000',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                }
            ]
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#EA4D95',
        },
        secondary: {
            main: '#263238',
        },
        accent: {
            main: '#EA4D95',
        },
        text: {
            primary: '#eceff1',
        }
    },
});

export default pleshDark
